<template>
  <div>
    <div class="main-title">类型列表</div>
    <div class="content-list-page page-content">
      <div class="margin-b-sm margin-l-sm">

        <span class=" fl-r">
          <router-link to="/cate/add" class="router-link">
            <el-button type="primary" size="small">新增</el-button>
          </router-link>
        </span>
      </div>

      <div class="table-content margin-t-sm">
        <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" v-loading="loading" style="width: 100%;background: #fff;">
          <el-table-column label="ID" property="id" width="80" align="center">
          </el-table-column>

          <el-table-column property="name" label="名字" align="center">
          </el-table-column>

          <el-table-column property="created_at" label="创建时间" align="center">
          </el-table-column>

          <el-table-column label="状态" property="status" align="center">
            <template slot-scope="scope">
              <div>
                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2" @change="updataStatus(scope.row.id, scope.row.status)" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="300" label="操作">
            <template slot-scope="scope">
              <span class="margin-r-sm">
                <el-link slot="reference" type="primary" @click="editAimActivity(scope.row.id)">编辑
                </el-link>
              </span>
              <span>
                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？" @confirm="deleted(scope.row.id)">
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch margin-l-sm">
        <span class="fl-l">
          <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize" @current-change="setPage" :total="total">
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '../../../js/config'
export default {
  name: "aimActivity",
  data () {
    return {
      //图片前缀
      path: config.imageUrl,
      //表单加载动画
      loading: false,
      //表单数据
      tableData: [],
      //分页
      page: 1,
      pageSize: 10,
      total: 0,
    }
  },
  created () {
    this.getList();
  },
  methods: {
    ...mapActions('hearBook', ['getCateList', 'setCateDel']),

    //搜索
    search () {
      if (this.searchData.datePick == undefined) this.searchData.datePick = []
      this.getList();
    },
    //分页
    setPageSize (pageSize) {
      this.pageSize = pageSize
      this.getList()
    },
    setPage (page) {
      this.page = page
      this.getList()
    },

    //编辑
    editAimActivity (row) {
      this.$router.push('/cate/detail/' + row)
    },
    //删除
    async deleted (row) {
      const { res_info } = await this.setCateDel(row)
      if (res_info != 'ok') return
      this.$message.success('删除成功');
      await this.getList()
    },
    //获取列表数据
    async getList () {
      this.loading = true
      let from = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      }
      const { res_info, data } = await this.getCateList(from)
      if (res_info != 'ok') return
      this.total = data.total
      this.tableData = data.list
      this.loading = false
    }
  }
}
</script>
